@import "src/custom.scss";

.slider {
  position: relative;
//background-color: $lila;
  .slide {
    position: relative;
    top: 0;
    min-height: 50vh;
    @include phone {
      min-height: unset;
    }
    background-size: cover;
    //margin-top: -5px;
    width: 100%;
max-width: 100%;
    &.invisible {
      position: relative;
      visibility: hidden;
    }
    img {
      max-width: 100%;
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  .slidecontent {
    position: absolute;
    top: 20%;
    left: 5%;
    width: 20%;
    text-align: left;
    @include phone {
      font-size: 14px;
      top: 10%;
    }
    .text {
      text-transform: uppercase;
      font-weight: 700;
      color: #fff;
      font-size: 40px;
      text-align: left;
      @include phone {
        font-size: 14px;

      }
    }
    @include phone {
      a.button {
        font-size: 12px;
      }
    }
  }
  .slidemenu {
    position: absolute;
    bottom: 10px;
    display: flex;
    width: 100%;
    justify-content: center;
    .point {
      margin: 10px;
      transition: background-color 0.3s ease;
      cursor: pointer;
      &.active {
        cursor: default;
        background-color: #fff;
      }
      background-color: #ccc;
      width: 20px;
      border-radius: 50%;
      height: 20px;
      @include mobile {
        width: 12px;
        height: 12px;
        margin: 0 10px;
      }
    }
  }
}
