@import "src/custom.scss";


.social {
  //display: flex;
  //padding: 0 30px;
    position: absolute;
  right: 0;
    top: 50px;

  .circle {
    //padding: 20px;
  }
}
.social {
  //position: absolute;
  //top: 15vh;

  right: 0;

  img {
    width: 2vw;
min-width: 15px;
  }

  .circle {
    cursor: pointer;

    a {
      border-radius: 50% 0 0 50%;
      padding: 15px;

      @include mobile {
        padding: 10px;

      }
      display: block;
      opacity: 1;
      margin-bottom: 5px;
      transition: 0.5s transform ease;
      background-color: rgba(#eee,0.5);

      img {
        display: block;
      }

      &:hover {
        opacity: 1;
        //transform: scale(1.2);
        //padding-right: 30px;
        img {
          transition: 0.2s transform ease;
        transform: scale(1.2);
        }
        //padding-right: 30px;





      }
    }

  }
}

