@import "src/custom.scss";

.bubbles {
  display: flex;
  padding: 10px 40px 0;
  flex-wrap: wrap;
  @include phone {
    padding: 0;
  }

  &.hellgruen {
    background-color: $hellgruen;
  }

  &.hellblau {
    background-color: $hellblau;
  }

  &.weiss {
    background-color: #fff;
  }

  .field {
    min-width: 33.33%;
    height: calc(33.33vw - 32px);
    @include phone {
      width: 90%;
      height: 90vw;
      //padding: 10px 7.5%;
    }
    position: relative;

    &.field1 {

      background: linear-gradient(75deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.1) 15%, rgba(255, 255, 255, 0) 100%);

      .bubble {
        &.pic {
          width: 70%;
          left: 25%;
          top: 25%;
          height: 70%;
          @include phone {

          }
        }

        &.color {
          background-color: $gruen;
          left: 10%;
          top: 15%;
        }
      }
    }

    &.field2 {
      .bubble {
        &.pic {
          width: 60%;
          left: 25%;
          top: 15%;
          height: 60%;
        }

        &.color {
          left: 60%;
          background-color: $tuerkis;
          top: 0%;
        }
      }
    }

    &.field3 {
      background: linear-gradient(105deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.1) 85%, rgba(255, 255, 255, 0.8) 100%);

      .bubble {
        &.pic {
          width: 55%;
          left: 20%;
          top: 35%;
          height: 55%;
        }

        &.color {
          left: 63%;
          top: 22%;
          background-color: $lila;
        }
      }
    }

    .smallbubbles {
      .bubble {

      }
    }

    .bubble {
      position: absolute;

      &.gruen {
        background-color: $gruen !important;
      }

      &.lila {
        background-color: $lila !important;
      }

      &.tuerkis {
        background-color: $tuerkis !important;
      }


      &.pic {
        width: 50%;
        left: 25%;
        top: 25%;
        height: 50%;
        background-size: cover;
        background-color: $hellgruen;
        border-radius: 50%;
        z-index: 2;
&.link {
  .overlay {
    opacity: 0.8;
    cursor: pointer;
      transform: scale(1);
    &:hover, &.active {
      transform: scale(1.2);
    }
  }
}
        .overlay {
          width: 80%;
          height: 60%;
          border-radius: 50%;
          opacity: 0;
          transition: all 0.5s ease;

          &:hover, &.active {
            opacity: 1;
          }

          font-size: 18px;
          @include mobile {
            font-size: 16px;
          }
          background-color: rgba(#fff, 0.4);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-evenly;
          font-weight: 600;
          padding: 20% 10%;
          color: #333;
        }
      }

      &.color {
        width: 35%;
        left: 10%;
        top: 15%;
        height: 35%;
        z-index: 1;
        border-radius: 50%;
        color: #fff;
        font-size: 20px;
        font-weight: 600;
        @include laptop {
          font-size: 16px;

        }
        @include phone {
          font-size: 14px;
        }

        .text {
          padding-top: 20%;
          padding-left: 20%;
          padding-right: 20%;
        }


      }
    }
  }
}


