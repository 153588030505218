@import "src/custom.scss";

.footer {
  background-color: #fff;
  width: 100%;
  padding-bottom: 20px;
}

a {
  color: #444;
  text-decoration: none;
  //text-transform: uppercase;
  font-weight: 400;
  @include phone {
    font-size: 14px;
    margin: 10px 0;
  }

  &.router-link-exact-active {


    color: $gruen;
    font-weight: 700;

  }
}
