@import "src/custom.scss";


.cart {
  .inner {
    height: 100vh;overflow: auto;padding: 50px;
    @include phone {
      padding: 20px;
    }
  }
  &.hidden {
    transform: translateX(-100%)
  }

  @include phone {
    max-width: 100vw;
    max-height: 100vh;
    overflow: auto;
  }
  transition: transform 0.2s ease;
  color: #fff;
  position: fixed;
  left: 0;
  top: 0;
  width: 530px;
  height: 100vh;
  overflow-x: visible;
  background-color: $lila;
  z-index: 90000;
  text-align: left;

  .list {

  }
}

.cartswitch {
  .hook {
    background-color: $lila;
    height: 64px;
    width: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 30px 30px 0;
  }

  @include phone {
    top: 10px;
    transform: scale(0.7);
    transform-origin: top left;
  }

  //width: 50px;
  position: fixed;
  display: flex;

  &:hover {

    background-color: rgba(#fff, 0.9);
  }

  &:hover {

    //background-color: lighten($lila, 10%);
    //color: #fff;
    .text {
      display: flex;
    }
  }

  height: 64px;
  align-items: center;
  left: 0;
  top: 50px;
  color: $lila;
  //background-color: $lila;
  z-index: 80000;
  cursor: pointer;

  .text {
    text-align: left;
    margin-left: 10px;
    padding: 10px;
    //height: 54px;
    font-size: 20px;
    //line-height: 32px;
    text-transform: uppercase;
    font-weight: 700;
    display: none;

    &.visible {
      display: flex;
      @include mobile {
        display: none;
      }
    }


  }
}

