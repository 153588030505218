@import "src/custom.scss";

.gallery {
  padding-left: 1%;
  padding-right: 1%;
  margin: 30px;

  .block {
    min-width: 50%;
    max-width: 50%;
@include mobile {
  max-width: unset;
}
    .fullpic {
      .inner {
        padding: 0.5%;

        img {
          width: 100%;
          vertical-align: bottom;
          display: block;

        }
      }

    }
  }

  .grid {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .pic {
      width: 50%;
      min-width: 50%;

      .inner {
        position: relative;

        img {
          aspect-ratio: 400/296;
        }

        &.high {
          img {
            aspect-ratio: 4/6;
          }
        }

        //margin-left: 1%;
        padding: 1%;
        //margin-top: 0.5%;
        .overlay {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 98%;
          height: calc(97% + 1px);
          //background-color: rgba(blue,0.8);

          color: #fff;
          font-size: 36px;
          @include mobile {
            font-size: 24px;
          }
          font-weight: 700;
          text-transform: uppercase;
          opacity: 1;
          transition: opacity 0.2s ease;

          &:hover {
            opacity: 0;
          }
        }
      }

      img {
        width: 100%;
        display: block;

      }
    }
  }

  display: flex;
  flex-wrap: wrap;
}
