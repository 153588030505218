@import "src/custom.scss";

.opinions {
  padding: 20px 5% 50px;
  display: flex;
  flex-direction: column;

  h1 {
    text-align: left;
  }

  .slide-fade-enter-active {
    transition: all .6s ease;
  }

  .slide-fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    display: none;
  }

  .slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */
  {
    transform: translateX(10%);
    opacity: 0;
  }

  background-color: $hellblau;

  .customers {
    display: flex;
    flex-wrap: wrap;
    min-height: 200px;
    @include mobile {
      width: 100%;
      padding-top: 50px;
      order: 3;
    }

    & > div {
      position: relative;
      width: calc(50% - 60px);
      margin-right: 20px;

      padding: 30px 20px 30px 20px;
      @include mobile {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
      }

      text-align: left;
      border-top: 2px #fff solid;
      border-bottom: 2px #fff solid;

      .paranthese {
        font-size: 40px;
        color: $lila;
        position: absolute;
        left: calc(50% - 30px);
        top: -20px;
        background-color: $hellblau;
        padding: 0 20px;
      }
    }
  }

  .arrow {
    font-size: 30px;
    color: $lila;

    &.active {

      font-weight: 900;
    }

    cursor: pointer;
  }

  .pages {
    display: flex;
    justify-content: center;
    @include mobile {
      order: 2;
    }
  }
}
