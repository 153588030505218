
$lila: #764c70;
$gruen: #94c11e;
$tuerkis: #00b0c8;
$helllila:  #9f80b8;
$hellblau: #daf3f7;
$hellgruen: #f0f5de;


$phonewidth: 600px;
$mobilewidth: 750px;
$ipadwidth: 1080px;
$tabletwidth: 1250px;
$laptopwidth: 1440px;


$grid-breakpoints: (
        xs: 0,
        sm: $phonewidth,
        md: $mobilewidth,
        lg: $tabletwidth,
        xl: $laptopwidth
);


@mixin mobile {
  @media (max-width: #{$mobilewidth}) {
    @content;
  }
}

@mixin phone {
  @media (max-width: "#{$phonewidth}") {
    @content;
  }
}

@mixin notphone {
  @media (min-width: "#{$phonewidth}") {
    @content;
  }
}

@mixin landscape {
  @media (max-width: "#{$phonewidth}") and (orientation: landscape) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$tabletwidth}) {
    @content;
  }
}
@mixin ipad {
  @media (max-width: #{$ipadwidth}) {
    @content;
  }
}

@mixin laptop {
  @media (max-width: #{$laptopwidth}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: "#{$phonewidth}") {
    @content;
  }
}

@mixin touch {
  @media (hover: none) {
    @content;
  }
}

.nomobile {
  @include mobile {
    display: none!important;
  }
}

.onlymobile {
  @include desktop {
    display: none!important;
  }
}