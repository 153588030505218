@import "src/custom.scss";

.gallery {

  padding-top: 40px;
  padding-bottom: 40px;

background-color: rgba($hellblau,0.5);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: calc(100vw);
  .bild {
   height: min(512px, 100vw);

    margin: 5px;

    img {
      max-height: 100%;
      max-width: 100%;
    }

  }
}
