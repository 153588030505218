@import "src/custom.scss";

.pagecontent {
  margin-top: 0px;
  position: relative;

  &.hellgruen {
    background-color: $hellgruen;
  }
}

.content {
  //max-width: 1000px;
  //padding:  20px;
  text-align: left;
  padding: 30px 5%;
  width: calc(100vw - 60px);
  background-color: $hellgruen;
}


