@import "src/custom.scss";

//@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@400;600;700;800&family=Roboto+Condensed:wght@400;700&display=swap');
/* barlow-semi-condensed-regular - latin */
@font-face {
  font-family: 'Barlow Semi Condensed';
  font-style: normal;
  font-weight: 400;
  src: local(''),
  url('assets/fonts/barlow-semi-condensed-v13-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('assets/fonts/barlow-semi-condensed-v13-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* barlow-semi-condensed-600 - latin */
@font-face {
  font-family: 'Barlow Semi Condensed';
  font-style: normal;
  font-weight: 600;
  src: local(''),
  url('assets/fonts/barlow-semi-condensed-v13-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('assets/fonts/barlow-semi-condensed-v13-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* barlow-semi-condensed-700 - latin */
@font-face {
  font-family: 'Barlow Semi Condensed';
  font-style: normal;
  font-weight: 700;
  src: local(''),
  url('assets/fonts/barlow-semi-condensed-v13-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('assets/fonts/barlow-semi-condensed-v13-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* barlow-semi-condensed-800 - latin */
@font-face {
  font-family: 'Barlow Semi Condensed';
  font-style: normal;
  font-weight: 800;
  src: local(''),
  url('assets/fonts/barlow-semi-condensed-v13-latin-800.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('assets/fonts/barlow-semi-condensed-v13-latin-800.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

body {
  margin: 0;
}

#app {
  font-family: 'Barlow Semi Condensed', 'Source Sans Pro', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-size: 18px;
  @include phone {
    font-size: 16px;
    max-width: 100vw;
  }
  color: #2c3e50;
  letter-spacing: 0.03em;
}

.home {
  padding-bottom: 20px;
}

.actionbutton {
  background-color: #95C11E;
  display: inline-flex;
  align-items: center;
  align-self: flex-end;
  padding: 10px;
  border-radius: 15px;
  margin: 10px;
  outline: none;
  color: #fff;
  cursor: pointer;

  &.active {
    background-color: darkred;
  }

  &:hover {
    background-color: darken(#95C11E, 10%);

    &.active {
      background-color: darken(darkred, 10%);;
    }
  }
}

h1 {
  font-weight: 900;
  text-transform: uppercase;
  color: $lila;
  font-size: 36px;
  @include phone {
    font-size: 24px;
    margin: 20px 0;
  }
}

h2 {
  text-transform: uppercase;
  font-weight: 700;
}

a {
  text-decoration: none;
  color: #333;
}

.lila {
  color: $lila;
}

a.button {
  background-color: $gruen;
  color: #fff;
  margin-top: 10px;
  text-align: left;
  padding: 5px 10px;
  display: inline-block;
  border-radius: 10px;
}

.flexcenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.wp-block-video {
  max-width: 720px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

//.wp-video {
//  max-width: calc(100vw - 30px);
//  video {
//    max-width: calc(100vw - 80px);
//  }
//}

//.wp-video {
//  max-width: 100%;
//  height: 0px;
//  overflow: hidden;
//  padding-bottom: 10000px;
//  position: relative;
//
//  iframe,
//  video {
//    position: absolute;
//    top: 0;
//    left: 0;
//    width: 100%;
//    height: 100%;
//  }
//}


/* source-sans-pro-regular - latin */
//@font-face {
//  font-family: 'Source Sans Pro';
//  font-style: normal;
//  font-weight: 400;
//  src: local(''),
//  url('assets/fonts/source-sans-pro-v14-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
//  url('assets/fonts/source-sans-pro-v14-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
//}
///* source-sans-pro-600 - latin */
//@font-face {
//  font-family: 'Source Sans Pro';
//  font-style: normal;
//  font-weight: 600;
//  src: local(''),
//  url('assets/fonts/source-sans-pro-v14-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
//  url('assets/fonts/source-sans-pro-v14-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
//}
///* source-sans-pro-700 - latin */
//@font-face {
//  font-family: 'Source Sans Pro';
//  font-style: normal;
//  font-weight: 700;
//  src: local(''),
//  url('assets/fonts/source-sans-pro-v14-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
//  url('assets/fonts/source-sans-pro-v14-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
//}
