@import "src/custom.scss";

.menuswitch {
  display: none;
  @include mobile {
    display: flex;
  }
  width: 95%;
  justify-content: flex-end;

  img {
    max-width: 32px;
    position: absolute;
    top: 15px;
    right: 15px;
  }
}

.logo {
  padding: 40px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-wrap: wrap;

  img {
    width: 300px;
  }

  .places {
    color: $lila;
    font-weight: 700;
    padding-left: 50px;
    @include phone {
      padding-left: 0;
    }
  }
}

.menu {
  display: flex;
  justify-content: space-around;
  border-top: 1px solid #aaa;
  border-bottom: 1px solid #aaa;
  padding: 10px 0;
  background-color: #fff;
  @include mobile {
    display: none;
    &.switched {
      display: block;
    }
    a {
      display: block;
    }
  }

  &.fixed {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
  }


  a {
    color: #444;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 400;
    @include phone {
      font-size: 24px;
      margin: 10px 0;
    }

    &.router-link-exact-active {


      color: $gruen;
      font-weight: 700;

    }
  }
}
