@import "src/custom.scss";

.patience {
  background-color: rgba(#fff, 0.9);
  width: 100%;
  height: 100vh;
  top: 0;
  position: fixed;
  z-index: 300000;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h2.red {
    color: darkred
  }
}
