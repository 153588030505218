@import "src/custom.scss";

.line {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #91728C;

  a {
    cursor: pointer;

    &:hover {
      img {

        transform: scale(1.1);
      }
    }
  }
}

.carttrigger {
  opacity: 0.6;

  &.active {
    opacity: 1;
  }
}

.active {
  font-weight: bold;

  .name {
  }
}
