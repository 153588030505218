@import "src/custom.scss";

.wrap {
  display: flex;
  justify-content: stretch;
  flex-wrap: wrap;
}
.widetext {
  text-align: left;
  color: #333;
  padding: 30px 5%;
  h1 {
    color: $lila;
  }
  .widecontent {
    max-width: 60%;
    @include mobile {
      max-width: 100%;
    }
  }

  &.weiss {

  }

  &.hellblau {


    background-color: $hellblau;
  }

  &.lila {
    background-color: $lila;
    color: #fff;
    h1 {
      color: #fff;
    }
  }
  &.cloud {
    background-image: url("../../assets/img/PF_Website-Wolke Banner_Weiss.png");
    background-size:20%;
    background-repeat: no-repeat;
    background-position: right 0 bottom -2px;
  }
}

.widecontent {

}

.buttonbox {
  width: 40%;
  @include mobile {
    width: 100%;
  }
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 20px;
}
