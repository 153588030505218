@import "src/custom.scss";

.lilabox {
  margin: 20px 5%;
  @include mobile {
    margin: 10px 5%;
  }
  text-align: left;
  border: 1px solid $lila;
  position: relative;

  .content {
    &.closed {
      height: 0;
      overflow: hidden;
    }

    background-color: #fff;

    .inner {

      padding: 10px 30px;
      @include mobile {
        padding: 10px 15px;
      }
    }

    .textfeld {
      width: 50%;
      padding: 0 20px;

      &.wide {
        width: 80%;
      }
      @include mobile {
        padding: 0;
        width: 100%;
        &.wide {
          width: 100%;
        }
      }
    }

    h2 {
      color: $lila;
      text-transform: uppercase;
    }

  }

  .kopf, .foot {
    background-color: $lila;
    padding: 30px;
    color: #fff;
    font-size: 24px;
    @include mobile {
      font-size: 1em;
      padding: 10px 15px;
    }
    margin: 0;
    font-weight: 600;
  }

  .kopf {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.acc {
      cursor: pointer;
    }

    .triangle {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 20px 10px 0 10px;
      border-color: #fff transparent transparent transparent;
      transition: transform 0.2s;

      &.open {
        transform: rotateX(180deg);
      }
    }
  }


}
